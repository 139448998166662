import React, { useEffect, useState } from "react";
/* AntD */
import { Button, Form, message, Upload } from "antd";
import { useForm } from "antd/lib/form/Form";
/* Components */
import { DynamicFields } from "./dynamicFields";
import IconByMimeType from "../../components/Utility/IconByMimeType";
import { AntdFormSelectField, Layout, AntdFormDragger } from "../../components";
/* Services and interfaces */
import { getDocumentS3Keys } from "../../services/documents";
import { UploadFile, UploadListType } from "antd/lib/upload/interface";
import { DocumentType, getDocumentTypes } from "../../services/documentTypes";
/* Utility */
import JSZip from "jszip";
import { v4 as uuidv4 } from "uuid";
import {
  S3Client,
  PutObjectCommand,
  PutObjectCommandInput,
} from "@aws-sdk/client-s3";
import {
  NotificationInterface,
  notify,
  operationError,
  operationInfo
} from "../../services/notification-wrapper";
import {
  uploadTranslations as upload,
  commonTranslations as common,
  getRequiredValidation,
  fileToBlob,
  toBase64,
  uploadTranslations,
  commonTranslations,
} from "../../helpers";

const DocumentUpload = () => {
  const [form] = useForm();
  const [changedFields, setChangedFields] = useState(null)
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [formStructure, setFormStructure] = useState<any>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    getTipiDocumento();
  }, []);

  const getTipiDocumento = async () => {
    try {
      const responseTipiDoc = await getDocumentTypes({ limit: 10000, order_by: "label", sort_dir: "asc" });
      setDocumentTypes(responseTipiDoc.documentType);
    } catch (error) {
      console.log("Errore", error);
    }
  };

  const onDocumentTypeChange = (value) => {
    const docType = documentTypes.filter(
      (documentType) => documentType.id === value
    )[0];
    clearDynamicFields();
    setFormStructure(docType?.formStructure);
  };

  const uploadDocument = async (formValues) => {
    if (!fileList?.length) return;
    try {
      setIsSaving(true);
      const keys = await getDocumentS3Keys();

      delete formValues.documentsList;
      const zip = new JSZip();

      /* Aggiunta documenti nel file zip */
      const mimeTypes = {};
      for (const document of fileList) {
        mimeTypes[document.name] = document.type;
        const base64 = await toBase64(await fileToBlob(document));
        zip.file(document.name, base64.split(",")[1], { base64: true });
      }

      if (formValues?.CIG) {
        formValues.CIG = formValues.CIG.split(" - ")[0];
      }

      /* Creazione JSON metadati */
      const jsonb64 = await toBase64(
        new Blob(
          [
            JSON.stringify({
              ...formValues,
              Tipo_Evento: "clientUpload",
              sourceSystem: "PDG_CLIENT",
              mimeTypes,
              sessionUUID: keys.sessionUUID,
            }),
          ],
          { type: "application/json" }
        )
      );
      zip.file("metatadata.json", jsonb64.split(",")[1], { base64: true });

      zip.generateAsync({ type: "uint8array" }).then((content) => {
        const s3 = new S3Client({
          credentials: {
            accessKeyId: keys.id,
            secretAccessKey: keys.secret,
          },
          region: "eu-west-1",
        });

        const dataToUpload: PutObjectCommandInput = {
          Bucket: keys.bucketname,
          Key: `${new Date().getTime()}_document_upload_${uuidv4()}.zip`,
          Body: content,
          ContentType: "application/zip",
        };

        s3.send(new PutObjectCommand(dataToUpload))
          .then((res) => {
            notify(operationInfo({ descriptionKey: "uploadInfoDesc", messageKey: "uploadInfoMessage" }));
            resetForm();
          })
          .catch((err) => {
            notify(operationError());
          })
          .finally(() => {
            setIsSaving(false);
          });
      });
    } catch (error) {
      setIsSaving(false);
      notify(operationError());
    }
  };

  const resetForm = () => {
    form.resetFields();
    setFileList([]);
    setFormStructure(null);
  };

  const clearDynamicFields = () => {
    form.setFieldsValue({
      CIG: undefined,
      Anno: undefined,
      Fornitore: undefined,
      ODL: undefined,
      Mese: undefined,
      businessUnit: undefined,
      applicationCode: undefined,
      ODA: undefined,
      formaContrattualeNominato: undefined
    });
  };
  const allowedFiles = ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "p7m"];
  return (
    <Layout
      title={upload.title}
      disableInsert={true}
      isSaving={isSaving}
      onSave={() => form.submit()}
    >
      <div className="row d-flex justify-content-center mx-3">
        <section className="col-sm-12 col-md-10 col-lg-8 p-3 section">
          <Form
            onFinish={uploadDocument}
            name="documentsForm"
            className="row px-2"
            form={form}
            onValuesChange={(changedValues) => setChangedFields(changedValues)}
          >
            <div className="px-3 w-100">
              <h4>{upload.sectionDatiDoc}</h4>
              <hr />
              <p>{common.formValidations.requiredInfo}</p>
            </div>
            <AntdFormSelectField
              name="documentType_id"
              label={upload.labels.documentType}
              placeholder={upload.placeholders.documentType}
              options={documentTypes.map((documentType) => ({
                name: documentType.label,
                value: documentType.id,
              }))}
              rules={getRequiredValidation("upload", "documentType")}
              onChange={onDocumentTypeChange}
              cols="col-12 mb-4"
              required
            />

            {formStructure && (
              <DynamicFields
                changedFields={changedFields}
                formRef={form}
                formStructure={formStructure}
                clearFields={clearDynamicFields}
              />
            )}

            <AntdFormDragger
              inputName="documentsList"
              label={upload.labels.documents}
              cols="col-12"
              showUploadList={true}
              uploadProps={{
                multiple: true,
                onRemove(removedFile) {
                  setFileList(
                    fileList.filter(
                      (element) => element.uid !== removedFile.uid
                    )
                  );
                },
                onChange: (changes) =>
                  !changes?.fileList?.length && setFileList([]),
                iconRender(file: UploadFile<any>, listType?: UploadListType) {
                  return <IconByMimeType mimeType={file.type} size={30} />;
                },
                beforeUpload(newFile, newFileList) {
                  if (
                    allowedFiles.indexOf(newFile.name.split(".").pop()) > -1
                  ) {
                    setFileList((fl) => [...fl, newFile]);
                    return;
                  } else {
                    const notifyWarnObj: NotificationInterface = {
                      type: "warning",
                      params: {
                        message: commonTranslations.notifications.warning,
                        description: uploadTranslations.validationMessages.typeError,
                        key: "unwantedFileTypesWarning"
                      },
                    };
                    notify(notifyWarnObj);
                  }
                }
              }}
              type="file"
              fileList={fileList}
              required
              rules={[
                {
                  message: uploadTranslations.validationMessages.documents + '. ' + uploadTranslations.validationMessages.typeError,
                  required: true,
                  validator: () =>
                    !fileList.length ? Promise.reject() : Promise.resolve(),
                },
              ]}
            />

            <div className="col-12 d-flex justify-content-end">
              <Button
                htmlType="button"
                className="mr-2"
                onClick={resetForm}
                disabled={isSaving}
              >
                {common.buttons.reset}
              </Button>
              <Button
                htmlType="submit"
                className="ml-2"
                type="primary"
                disabled={isSaving}
              >
                {common.buttons.save}
              </Button>
            </div>
          </Form>
        </section>
      </div>
    </Layout>
  );
};

export default DocumentUpload;
